<template>
    <v-container fluid>
        <v-row>
            <v-col 
                style="display: flex; align-content: center; align-items: center;"
                cols="6"
                lg="10"
                md="8"
                sm="6"
            >
                <h3>To Do{{ categoryName === 'toDoList' ? '' : categoryName }}</h3>
            </v-col>
            <v-col 
                style="text-align: right;"
                cols="6"
                lg="2"
                md="4"
                sm="6"
            >
                <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn 
                            v-bind="attrs"
                            v-on="on"
                            :to="{ name: 'toDoForm', params: {id: 0} }"
                        >
                            New
                        </v-btn>
                    </template>
                    <span>New</span>
                </v-tooltip>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-form @submit.prevent="getRegisters">
                    <v-text-field
                        v-model="filter.fastSearch"
                        label="Quick Search"
                        prepend-inner-icon="mdi mdi-magnify"
                        outlined
                        single-line
                        @click:prepend-inner="getRegisters"
                        dense
                    ></v-text-field>
                </v-form>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-data-table
                    :headers="headers"
                    :items="filteredSearch"
                    :options.sync="options"
                    :loading="loading"
                    :items-per-page="10"
                    no-data-text="No Record Found"
                >
                    <template v-slot:item.id="{ item }">
                        <span
                            :style="item.readDescription === 'NO' ? 'color: inherit' : 'color: var(--color__silver)'"
                        >
                            {{ item.id }}
                        </span>
                    </template>

                    <template v-slot:item.dateRegisterFormatted="{ item }">
                        <span
                            :style="item.readDescription === 'NO' ? 'color: inherit' : 'color: var(--color__silver)'"
                        >
                            {{ item.dateRegisterFormatted }}
                        </span>
                    </template>

                    <template v-slot:item.dateToDoFormatted="{ item }">
                        <span
                            :style="item.readDescription === 'NO' ? 'color: inherit' : 'color: var(--color__silver)'"
                        >
                            {{ item.dateToDoFormatted }}
                        </span>
                    </template>

                    <template v-slot:item.title="{ item }">
                        <span
                            :style="item.readDescription === 'NO' ? 'color: inherit' : 'color: var(--color__silver)'"
                        >
                            {{ item.title }}
                        </span>
                    </template>

                    <template v-slot:item.userNameRegister="{ item }">
                        <span
                            :style="item.readDescription === 'NO' ? 'color: inherit' : 'color: var(--color__silver)'"
                        >
                            {{ item.systemDescription == "YES" ? "System" : item.userNameRegister == userLoggedGetters.firstName ? 'Myself' : item.userNameRegister }}
                        </span>
                    </template>

                    <template v-slot:item.userNameResponsible="{ item }">
                        <span
                            :style="item.readDescription === 'NO' ? 'color: inherit' : 'color: var(--color__silver)'"
                        >
                            {{ item.userNameResponsible == userLoggedGetters.firstName ? 'Myself' : item.userNameResponsible }}
                        </span>
                    </template>

                    <template v-slot:item.descriptionTodoCategory="{ item }">
                        <span
                            :style="item.readDescription === 'NO' ? 'color: inherit' : 'color: var(--color__silver)'"
                        >
                            {{ item.descriptionTodoCategory }}
                        </span>
                    </template>

                    <template v-slot:item.systemDescription="{ item }">
                        <span
                            :style="item.readDescription === 'NO' ? 'color: inherit' : 'color: var(--color__silver)'"
                        >
                            {{ item.systemDescription }}
                        </span>
                    </template>

                    <template v-slot:item.urgentDescription="{ item }">
                        <v-chip
                            :style="item.readDescription === 'YES' ? 'color: var(--color__silver)' : item.urgentDescription == 'YES' ? 'var(--color__red)' : 'var(--color__main)' "
                            :color="item.readDescription === 'YES' ? 'var(--color__silver)' :item.urgentDescription == 'YES' ? 'var(--color__red)' : 'var(--color__main)'"
                            outlined
                            dark
                        >
                            {{ item.urgentDescription == 'YES' ? 'URGENT' : item.urgentDescription }}
                        </v-chip>
                    </template>

                    <template v-slot:item.doneDescription="{ item }">
                        <v-chip
                            :style="item.readDescription === 'YES' ? 'color: var(--color__silver)' : item.doneDescription == 'YES' ? 'var(--color__status_pending)' : 'var(--color__main)' "
                            :color="getStatusColor(item)"
                            outlined
                            dark
                        >
                            {{ item.doneDescription == "YES" ? "Done" : "Pending" }}
                        </v-chip>
                    </template>
                    
                    <template v-slot:item.action="{item}">
                        <ActionList 
                            :id="item.id" 
                            :link="'toDoForm'"
                            :showButtons="{
                                edit: item.done === 0 && userLoggedGetters.id === item.idUserRegister,
                                delete: item.done === 0 && userLoggedGetters.id === item.idUserRegister,
                                viewToDo: userLoggedGetters.id == item.idUserResponsible,
                                markDoneUnDone: item.done === 0 && userLoggedGetters.id == item.idUserResponsible,
                                cloneToDo: item.system === 0
                            }"
                            @viewToDo="viewToDo"
                            @confirmDelete="confirmDelete"
                            @confirmDoneUnDone="confirmDoneUnDone"
                            @confirmCloneToDo="confirmCloneToDo"  />
                    </template>
                </v-data-table>
            </v-col>
        </v-row>

        <ActionDialog 
            v-on:methodConfirmToCall="dialog.methodConfirm"
            :showDialog.sync="dialog.show"
            :headerTitle="dialog.headerText"
            :bodyText="dialog.bodyText"
            :params="dialog.params"
        />

    </v-container>
</template>

<script>
    import { mapGetters } from 'vuex';
    import Helpers from '@/utilities/Helpers';
    import ActionList from "@/components/Layout/ActionList";
    import ActionDialog from "@/components/Layout/ActionDialog";
    export default ({

        components: {
            ActionList: ActionList,
            ActionDialog: ActionDialog
        },

        mixins: [Helpers],

        props: {
            categoryName: {
                default: ""
            }
        },

        data: () => ({

            loading: false,

            filter: {
                fastSearch: ''
            },

            dialog: {
                show: false,
                headerText: "",
                bodyText: "",
                methodConfirm: ( () => {} ),
                params: null
            },

            headers: [
                { text: "ID", value: "id", sortable: true, size: "5%" },
                { text: "Created On", value: "dateRegisterFormatted", sortable: true },
                { text: "Target Date", value: "dateToDoFormatted", sortable: true },
                { text: "Title", value: "title", sortable: true },
                { text: "Author", value: "userNameRegister", sortable: true },
                { text: "Assigned To", value: "userNameResponsible", sortable: true },
                { text: "Category", value: "descriptionTodoCategory", sortable: true },
                { text: "System", value: "systemDescription", sortable: true },
                { text: "Urgent", value: "urgentDescription", sortable: true },
                { text: "Status", value: "doneDescription", sortable: true },
                { text: "Actions", value: "action", sortable: false, align: "center" }
            ],

            options: { rowsPerPage: 10, page: 1 },

            listToDo: []
        }),

        computed: {
            ...mapGetters('logInModule', { userLoggedGetters: 'searchState' }),

            filteredSearch: function() {

                if (this.listToDo != null && this.listToDo != undefined) {
                    return this.listToDo.filter((toDoFilter) => {
                        
                        let filter = this.filter.fastSearch.toLowerCase();

                        var title = toDoFilter.title.toLowerCase().match(filter)
                        var userNameRegister = toDoFilter.userNameRegister != null && toDoFilter.userNameRegister != undefined ? toDoFilter.userNameRegister.toLowerCase().match(filter) : ""
                        var userNameResponsible = toDoFilter.userNameResponsible != null && toDoFilter.userNameResponsible != undefined ? toDoFilter.userNameResponsible.toLowerCase().match(filter) : ""
                        var descriptionTodoCategory = toDoFilter.descriptionTodoCategory != null && toDoFilter.descriptionTodoCategory != undefined ? toDoFilter.descriptionTodoCategory.toLowerCase().match(filter): ""
                        var urgentDescription = toDoFilter.urgentDescription != null && toDoFilter.urgentDescription != undefined ? toDoFilter.urgentDescription.toLowerCase().match(filter) : ""
                        var systemDescription = toDoFilter.systemDescription != null && toDoFilter.systemDescription != undefined ? toDoFilter.systemDescription.toLowerCase().match(filter) : ""
                        var readDescription = toDoFilter.readDescription != null && toDoFilter.readDescription != undefined ? toDoFilter.readDescription.toLowerCase().match(filter) : ""
                        var doneDescription = toDoFilter.doneDescription != null && toDoFilter.doneDescription != undefined ? toDoFilter.doneDescription.toLowerCase().match(filter) : ""
                        var id = toDoFilter.id.toString().match(filter);

                        if(title != null) { return title; } 
                        else if(userNameRegister != null) { return userNameRegister; } 
                        else if(userNameResponsible != null) { return userNameResponsible; } 
                        else if(descriptionTodoCategory != null) { return descriptionTodoCategory; } 
                        else if(urgentDescription != null) { return urgentDescription; } 
                        else if(systemDescription != null) { return systemDescription; } 
                        else if(readDescription != null) { return readDescription; } 
                        else if(doneDescription != null) { return doneDescription; } 
                        else { return id; }
                    });
                }
                else {
                    return null;
                }
            }
        },

        watch: {
            categoryName() {

                if (this.categoryName === "toDoList") {
                    let jsonToDoCategory = {
                        urgent: 0,
                        system: 0,
                        assignedToMe: 0,
                        idTodoCategory: 0
                    }

                    localStorage.setItem('jsonToDoCategory', JSON.stringify(jsonToDoCategory));
                }

                setTimeout( () => { this.getRegisters() }, 1000);
            }
        },

        methods: {

            getStatusColor (item) {

                let color = "";

                switch (item.statusDescription) {

                    case "YES":
                        color = item.readDescription === 'YES' ?  'var(--color__cinza)' : 'var(--color__main)';
                        break;

                    default:
                        color = item.readDescription === 'YES' ?  'var(--color__cinza)' : 'var(--color__status_pending)';
                        break;
                }

                return color
            },

            async getRegisters() {

                let jsonToDoCategoryStr = localStorage.getItem('jsonToDoCategory');
                let toDoCategoryRequest = JSON.parse(jsonToDoCategoryStr);

                if (toDoCategoryRequest != null && toDoCategoryRequest != undefined) {
                    this.listToDo = await this.$store.dispatch("toDoModule/ListByCategory", toDoCategoryRequest);
                }
            },

            async viewToDo(id) {

                this.showLoading();

                await this.$store.dispatch("toDoModule/MarkAsReadUnRead", id);

                this.getRegisters();

                this.hideLoading();

            },

            confirmDelete(id) {

                this.dialog = {
                    show: true,
                    headerText: 'Confirmation',
                    bodyText: 'You will DELETE to-do, confirm your decision?',
                    methodConfirm: this.delete,
                    params: id
                };

            },

            async delete(id) {

                this.showLoading();

                let result = await this.$store.dispatch("toDoModule/Delete", id);

                if (result.success) {
                    this.showToast("success", "Success!", result.message);
                }
                else {
                    this.showToast("error", "Error!", result.message);
                }

                this.getRegisters();

                this.hideLoading();
            },

            confirmDoneUnDone(id) {

                this.dialog = {
                    show: true,
                    headerText: 'Confirmation',
                    bodyText: 'You will MARK as Done this To-Do, confirm your decision?',
                    methodConfirm: this.doneUnDone,
                    params: id
                };

            },

            async doneUnDone(id) {

                this.showLoading();

                let result = await this.$store.dispatch("toDoModule/MarkAsDoneUnDone", id);

                if (result.success) {
                    this.showToast("success", "Success!", result.message);
                }
                else {
                    this.showToast("error", "Error!", result.message);
                }

                this.getRegisters();

                this.hideLoading();
            },

            confirmCloneToDo(id) {

                this.dialog = {
                    show: true,
                    headerText: 'Confirmation',
                    bodyText: 'You will CLONE this To-Do, confirm your decision?',
                    methodConfirm: this.cloneToDo,
                    params: id
                };

            },

            async cloneToDo(id) {

                this.showLoading();

                let result = await this.$store.dispatch("toDoModule/Clone", id);

                if (result.success) {
                    this.showToast("success", "Success!", result.message);
                    
                    this.$router.push({ name: "toDoForm", params: { id: result.id } });
                }
                else {
                    this.showToast("error", "Error!", result.message);
                }

                this.getRegisters();

                this.hideLoading();
            },
        },

        async created() {
            this.showLoading();
            await this.getRegisters();
            this.hideLoading();
        }
    })
</script>
