var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticStyle:{"display":"flex","align-content":"center","align-items":"center"},attrs:{"cols":"6","lg":"10","md":"8","sm":"6"}},[_c('h3',[_vm._v("To Do"+_vm._s(_vm.categoryName === 'toDoList' ? '' : _vm.categoryName))])]),_c('v-col',{staticStyle:{"text-align":"right"},attrs:{"cols":"6","lg":"2","md":"4","sm":"6"}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"to":{ name: 'toDoForm', params: {id: 0} }}},'v-btn',attrs,false),on),[_vm._v(" New ")])]}}])},[_c('span',[_vm._v("New")])])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.getRegisters($event)}}},[_c('v-text-field',{attrs:{"label":"Quick Search","prepend-inner-icon":"mdi mdi-magnify","outlined":"","single-line":"","dense":""},on:{"click:prepend-inner":_vm.getRegisters},model:{value:(_vm.filter.fastSearch),callback:function ($$v) {_vm.$set(_vm.filter, "fastSearch", $$v)},expression:"filter.fastSearch"}})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.filteredSearch,"options":_vm.options,"loading":_vm.loading,"items-per-page":10,"no-data-text":"No Record Found"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('span',{style:(item.readDescription === 'NO' ? 'color: inherit' : 'color: var(--color__silver)')},[_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:"item.dateRegisterFormatted",fn:function(ref){
var item = ref.item;
return [_c('span',{style:(item.readDescription === 'NO' ? 'color: inherit' : 'color: var(--color__silver)')},[_vm._v(" "+_vm._s(item.dateRegisterFormatted)+" ")])]}},{key:"item.dateToDoFormatted",fn:function(ref){
var item = ref.item;
return [_c('span',{style:(item.readDescription === 'NO' ? 'color: inherit' : 'color: var(--color__silver)')},[_vm._v(" "+_vm._s(item.dateToDoFormatted)+" ")])]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('span',{style:(item.readDescription === 'NO' ? 'color: inherit' : 'color: var(--color__silver)')},[_vm._v(" "+_vm._s(item.title)+" ")])]}},{key:"item.userNameRegister",fn:function(ref){
var item = ref.item;
return [_c('span',{style:(item.readDescription === 'NO' ? 'color: inherit' : 'color: var(--color__silver)')},[_vm._v(" "+_vm._s(item.systemDescription == "YES" ? "System" : item.userNameRegister == _vm.userLoggedGetters.firstName ? 'Myself' : item.userNameRegister)+" ")])]}},{key:"item.userNameResponsible",fn:function(ref){
var item = ref.item;
return [_c('span',{style:(item.readDescription === 'NO' ? 'color: inherit' : 'color: var(--color__silver)')},[_vm._v(" "+_vm._s(item.userNameResponsible == _vm.userLoggedGetters.firstName ? 'Myself' : item.userNameResponsible)+" ")])]}},{key:"item.descriptionTodoCategory",fn:function(ref){
var item = ref.item;
return [_c('span',{style:(item.readDescription === 'NO' ? 'color: inherit' : 'color: var(--color__silver)')},[_vm._v(" "+_vm._s(item.descriptionTodoCategory)+" ")])]}},{key:"item.systemDescription",fn:function(ref){
var item = ref.item;
return [_c('span',{style:(item.readDescription === 'NO' ? 'color: inherit' : 'color: var(--color__silver)')},[_vm._v(" "+_vm._s(item.systemDescription)+" ")])]}},{key:"item.urgentDescription",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{style:(item.readDescription === 'YES' ? 'color: var(--color__silver)' : item.urgentDescription == 'YES' ? 'var(--color__red)' : 'var(--color__main)'),attrs:{"color":item.readDescription === 'YES' ? 'var(--color__silver)' :item.urgentDescription == 'YES' ? 'var(--color__red)' : 'var(--color__main)',"outlined":"","dark":""}},[_vm._v(" "+_vm._s(item.urgentDescription == 'YES' ? 'URGENT' : item.urgentDescription)+" ")])]}},{key:"item.doneDescription",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{style:(item.readDescription === 'YES' ? 'color: var(--color__silver)' : item.doneDescription == 'YES' ? 'var(--color__status_pending)' : 'var(--color__main)'),attrs:{"color":_vm.getStatusColor(item),"outlined":"","dark":""}},[_vm._v(" "+_vm._s(item.doneDescription == "YES" ? "Done" : "Pending")+" ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('ActionList',{attrs:{"id":item.id,"link":'toDoForm',"showButtons":{
                            edit: item.done === 0 && _vm.userLoggedGetters.id === item.idUserRegister,
                            delete: item.done === 0 && _vm.userLoggedGetters.id === item.idUserRegister,
                            viewToDo: _vm.userLoggedGetters.id == item.idUserResponsible,
                            markDoneUnDone: item.done === 0 && _vm.userLoggedGetters.id == item.idUserResponsible,
                            cloneToDo: item.system === 0
                        }},on:{"viewToDo":_vm.viewToDo,"confirmDelete":_vm.confirmDelete,"confirmDoneUnDone":_vm.confirmDoneUnDone,"confirmCloneToDo":_vm.confirmCloneToDo}})]}}])})],1)],1),_c('ActionDialog',{attrs:{"showDialog":_vm.dialog.show,"headerTitle":_vm.dialog.headerText,"bodyText":_vm.dialog.bodyText,"params":_vm.dialog.params},on:{"methodConfirmToCall":_vm.dialog.methodConfirm,"update:showDialog":function($event){return _vm.$set(_vm.dialog, "show", $event)},"update:show-dialog":function($event){return _vm.$set(_vm.dialog, "show", $event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }